<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O subdistrito de Ponte do Gama pertence ao Distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_monsenhor' }"
                  >Monsenhor Horta</router-link
                >, assim como
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu_de_baixo' }"
                  >Paracatu de Baixo</router-link
                >
                e
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu_de_cima' }"
                  >Paracatu de Cima</router-link
                >. Situa-se a 7 km de distância do núcleo urbano de Monsenhor
                Horta, a 27 km do centro de Mariana e a 140 km de Belo
                Horizonte, capital do Estado de Minas Gerais.
              </p>
              <p>
                A ocupação da região remonta à primeira metade do Século XVIII,
                motivada pela extração aurífera, atrelada a atividades rurais
                como lavouras e criação de gado.<sup>1</sup> A produção de
                alimentos tinha por objetivo suprir as necessidades dos
                assentamentos locais firmados em função da atividade minerária,
                bem como do núcleo urbano de Mariana, que ao longo dos anos no
                período colonial foi recebendo grande quantidade de pessoas em
                busca de ouro. Com a decadência da extração a partir do final da
                década de 70 do século XVIII (1778), as cidades de Ouro Preto e
                Mariana, que passaram por um período de abundância e
                desenvolvimento econômico vinculado à atividade minerária,
                submergiram à decadência financeira e escassez de alimentos.
              </p>
              <p>
                Diante disso, os municípios (sedes e suas localidades)
                voltaram-se para outras atividades a exemplo da produção
                agropecuária, que já fazia parte da economia local voltada para
                suprir as necessidades da população, mas não ocupando função de
                destaque na economia. A partir de meados do século XVIII,
                ganhando maior importância no século XIX e permanecendo até os
                dias atuais, as atividades produtivas ligadas ao cultivo da
                terra e criação de animais passaram a caracterizar os distritos
                e subdistritos de Marina, incluindo a região de Ponte do Gama.
                Salienta-se, entretanto, que a
                <span class="pink--text">extração de ouro</span> também
                manteve-se presente entre a produção local, mesmo que em menor
                escala ou relevância em nível nacional. Para a economia da
                região e para as famílias de Ponte do Gama e comunidades
                vizinhas, essa atividade favorece expressivamente os rendimentos
                e capacidade de compra.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_ponte_do_gama_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista do Rio Gualaxo do Norte em Ponte do Gama, Observa-se o relevo
            montanhoso ao fundo, coberto de mata nativa e de pastagens. Foto:
            Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="12" class="">
          <div class="iframe__box">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/JhvHbEqDIV4"
              title="Ponte do Gama - Mariana Território Atingido"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p style="font-size: 0.6em; text-align: center;">
            Ponte do Gama pelas histórias contadas por José de Felix, Milton e
            José de Jair, atingidos dessa comunidade. As festividades, o modo de
            viver das famílias e as transformações acarretadas pelo rompimento
            da barragem de Fundão que mesmo após seis anos continuam impedindo o
            retorno ao território e a reconstrução dos laços sociais.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-start"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_ponte_do_gama_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da paisagem com plantações de eucalipto ao fundo e área de
            pastagem. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Remetendo à ocupação original, o que foi identificado até o
                momento são antigas fazendas e alguns remanescentes
                arqueológicos, como as ruínas da Fazenda Samésia e locais de
                mineração. No mapa produzido pelo Padre Diogo Soares entre 1734
                e 1735, esta localidade é representada ao longo do Rio Gualaxo
                do Norte, demonstrando que a sua ocupação ocorreu na fase
                inicial da formação de Mariana e da Capitania de Minas Gerais.
              </p>
              <p>
                O antigo núcleo urbano de Ponte do Gama possivelmente foi
                implantado em um morro composto por mata fechada situado atrás
                das ruínas da referida Fazenda Samésia. Segundo o Sr. Geraldo
                Isabel dos Santos, 75 anos, natural de Paracatu de Baixo e que
                há 59 anos reside em Ponte do Gama, o seu pai trabalhou nessa
                fazenda e contava que no alto deste morro existem ruínas de uma
                capela e de um cemitério. <sup>2</sup>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Configuração da comunidade"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A comunidade caracteriza-se por seu aspecto rural, conformada
                por sítios e fazendas, não havendo um conjunto edificado
                adensado. Apresenta topografia montanhosa e terrenos cobertos
                por campos de pastagens e extensões de mata nativa.
              </p>
              <p>
                Os terrenos possuem além da habitação, construções acessórias de
                uso rural, com ênfase para o curral, relacionado à atividade de
                criação de bovino leiteiro. Nos quintais são cultivadas as
                hortas, pomares e criados animais de pequeno porte. As extensões
                de plantio de roçados e pastagem fazem parte das propriedades
                rurais e da paisagem.
              </p>
              <p>
                O ponto de convergência consiste na praça onde se localiza a
                Capela de Nossa Senhora Aparecida, o centro comunitário e a
                arena, na qual eram realizadas as cavalgadas. A praça e seu
                conjunto edificado encontram-se em área de planície cercada por
                plantio de eucalipto, adjacente ao Rio Gualaxo do Norte.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_ponte_do_gama_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da capela de Nossa Senhora Aparecida e do Centro comunitário.
            Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A capela e o centro comunitário receberam obras de recuperação
                após o local ser extremamente danificado pela lama de rejeitos.
                No entanto, o espaço das cavalgadas e o campo de futebol
                permanecem tomados pela lama e pela vegetação, cobertura verde
                inserida pela Fundação Renova que não se consolida como
                reflorestamento da área, mas que mascara o rejeito ainda
                presente. A praça com suas construções trata-se do local de
                socialização dos moradores, sendo que tanto a capela quanto o
                centro comunitário fazem parte da história recente de Ponte do
                Gama.
              </p>
              <p>
                As duas edificações são cercadas por mureta de alvenaria e
                gradil metálico, estando à frente delas o espaço gramado da
                praça, equipado com postes de iluminação. Internamente ao
                cercado, à frente das construções, foram implantados brinquedos
                infantis e equipamentos de ginástica. No lado oposto da via
                (estrada de terra), situava-se a área circular destinada à
                cavalgada.
              </p>
              <p>
                Além do campo de futebol e da arena, as cachoeiras e cursos
                d’águas também foram atingidos pelo rejeito e ainda se encontram
                soterrados impedindo a visitação dos moradores, que antes os
                tinham como referência de áreas de lazer e coletivização. A lama
                de rejeitos de minério submergiu ainda o nível térreo de duas
                casas de fazenda remanescentes do período colonial e suas
                estruturas de apoio.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira <sup>3</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_ponte_do_gama_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Praça localizada em frente à capela e do centro comunitário de Ponte
            do Gama. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização das comunidades tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p>
              <sup>2</sup> SANTOS, Geraldo Isabel. [maio de 2017].
              Entrevistador: Kleverson Lima. Mariana, Povoado da Ponte do Gama,
              Distrito de Monsenhor Horta, 2017.Relatório Técnico de História -
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão.
            </p>
            <p>
              <sup>3</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

#app
  > div
  > main
  > div
  > div
  > div.container
  > section
  > div
  > div
  > div.v-data-table.theme--light
  > div
  > table
  > thead
  > tr
  > th {
  color: black;
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}

.iframe__box {
  position: relative;
  padding-bottom: 56.1%;
  height: 0;
  overflow: hidden;

  border-radius: 15px;
}

.iframe__box iframe,
.iframe__box object,
.iframe__box embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
